import React, { useState, useEffect, useContext } from "react";
import "./AdminCourse.scss";
import { AuthContext } from "../../../App";
import toast from "react-hot-toast";
import CreateCoursePopup from "../components/CreateCoursePopup";
import DeleteCoursePopup from "../components/DeleteCoursePopup";
import EditCoursePopup from "../components/EditCoursePopup";
import { getAdminCourseData } from "../services/api.services";
import AppStartCourse from "../../../learning/user/components/AppStartCourse";


// import PaginationComponent from "../components/PaginationComponent";

const AdminCourse = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [courseDetail, setCourseDetails] = useState([]);

  useEffect(() => {
    getCourseData();
  }, []);

  let header = {
    Authorization: "Token " + state.token,
  };

  const getCourseData = async () => {
    try {
      const response = await getAdminCourseData(header);
      if (response.data.isSuccess) {
        setCourseDetails(response.data.dataInfo);
      } else {
        toast.error("Failed to fetch course data");
      }
    } catch (error) {
      toast.error("Error fetching course data: " + error.message);
    }
  };
  
  return (
    <div className="admin-course">
      <div className="admin-course-title">
        <div className="admin-course-title-text">All Courses</div>
      </div>
      <div className="admin-course-list">
      <AppStartCourse selectedCourses={courseDetail} isStartButton={false} />
      </div>
    </div>
  );
};

export default AdminCourse;
