import React, { useEffect, useState, useContext } from "react";
import "./Certificate.scss";
import {
  getCourseCertificate,
  sendCourseCertificate,
  downloadCourseCertificate,
} from "../services/api.services";
import toast from "react-hot-toast";
import { AuthContext } from "../../../App";
import { FaDownload, FaEnvelope } from "react-icons/fa";
import incompleteIcon from "../../images/icons/incomplete.png";

const Certificate = ({ courseId }) => {
  const { state, dispatch } = useContext(AuthContext);
  const [certificateUrl, setCertificateUrl] = useState(null);
  const [certificateError, setCertificateError] = useState(null);

  useEffect(() => {
    const fetchCertificate = async () => {
      try {
        const res = await getCourseCertificate(header, courseId);
        if (res.data.isSuccess) {
          setCertificateUrl(res.data.dataInfo.certificateUrl);
        } else {
          setCertificateError("Please complete the course to access your certificate.");
        }
      } catch (error) {
        setCertificateError("Failed to fetch certificate");
      }
    };

    fetchCertificate();
  }, [courseId, state.token]);

  const header = {
    Authorization: "Token " + state.token,
  };

  const handleDownload = async () => {
    if (courseId) {
      try {
        const response = await downloadCourseCertificate(header, courseId);

        if (response.status === 200) {
          const contentType = response.headers["content-type"];
          let extension = "png";
          if (contentType.includes("jpeg")) {
            extension = "jpeg";
          } else if (contentType.includes("gif")) {
            extension = "gif";
          }
          const imgBlob = new Blob([response.data], { type: contentType });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(imgBlob);
          link.download = `certificate.${extension}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error("Failed to download certificate:", response.statusText);
          toast.error("Failed to download certificate");
        }
      } catch (error) {
        console.error("Failed to download certificate:", error.message);
        toast.error("Failed to download certificate");
      }
    } else {
      toast.error("Course ID not available for download");
    }
  };

  const handleSendMail = async () => {
    try {
      const res = await sendCourseCertificate(header, courseId);
      if (res.data.isSuccess) {
        toast.success("Course Certificate sent to Email!!");
      } else {
      }
    } catch (error) {}
  };

  return (
    <div className="certificate">
      <div className="certificate-title">Course Certificate</div>
      {certificateError ? (
        <div className="certificate-fail">
          <div className="certificate-fail-body">
            <div className="certificate-fail-body-image">
              <img src={incompleteIcon} alt="Course Incomplete" />
            </div>
            <div className="certificate-fail-body-text">{certificateError}</div>
          </div>
        </div>
      ) : (
        <div className="certificate-body">
          <div className="certificate-body-image">
            {certificateUrl && <img src={certificateUrl} alt="Certificate" />}
          </div>
          <div className="certificate-body-buttons">
            {certificateUrl && (
              <button onClick={handleDownload}>
                <FaDownload /> Download
              </button>
            )}
            <button onClick={handleSendMail}>
              <FaEnvelope /> Send to Mail
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Certificate;
