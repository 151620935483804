import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./CourseSelector.scss";
import logo from "../../images/icons/logo.png";
import avatar from "../../images/icons/user_avatar.png";
import settingsIcon from "../../images/icons/settings.png";
import logoutIcon from "../../images/icons/logout.png";
import myLearningsIcon from "../../images/icons/curriculum.png";
import {
  getUserCourseData,
  getUserCourses,
  searchUserCourses,
} from "../services/api.services";
import AppStartCourse from "../components/AppStartCourse";
import AppNavbar from "../components/AppNavbar";
import toast from "react-hot-toast";
import { AuthContext } from "../../../App";
import AppLoader from "../components/AppLoader";
import { FaSearch } from "react-icons/fa";
import SearchBox from "../components/SearchBox";

const CourseSelector = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [courseNames, setCourseNames] = useState([]);
  const [deviceWidth, setDeviceWidth] = useState(getCurrentScreenWidth());
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [loader, setLoader] = useState(false);
  const [navbarData, setNavbarData] = useState([
    { name: "My Learning", icon: myLearningsIcon },
    { name: "Logout", icon: logoutIcon },
  ]);
  const [status, setStatus] = useState(null);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getUserDetail();
    localStorage.setItem("selectedNavTab", 0);
  }, []);

  useEffect(() => {
    if (searchText){
      findUserCourse(searchText)
    }else{
      fetchUserCourses();
    }
  }, [status, searchText]);

  useEffect(() => {
    const updateDeviceWidth = () => {
      setDeviceWidth(getCurrentScreenWidth());
    };
    window.addEventListener("resize", updateDeviceWidth);

    return () => {
      window.removeEventListener("resize", updateDeviceWidth);
    };
  }, [deviceWidth]);
  function getCurrentScreenWidth() {
    return window.innerWidth;
  }

  let header = {
    Authorization: "Token " + state.token,
  };

  const getUserDetail = async () => {
    await getUserCourseData(header)
      .then((resp) => {
        if (resp.data.isSuccess) {
          // setSelectedCourses(resp.data.dataInfo.course);
          // localStorage.setItem('userCourses', JSON.stringify(resp.data.dataInfo.course))
          localStorage.setItem(
            "userDetails",
            JSON.stringify(resp.data.dataInfo)
          );
          setLoader(true);
        } else {
          toast.error("Something went wrong!");
          setLoader(false);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        setLoader(false);
      });
  };

  const fetchUserCourses = async () => {
    // setLoader(false);
    try {
      let response;
      if (status) {
        response = await getUserCourses(header, status);
      } else {
        response = await getUserCourses(header);
      }

      if (response.status === 200) {
        setSelectedCourses(response.data.results);
        localStorage.setItem(
          "userCourses",
          JSON.stringify(response.data.results)
        );
        if (status === null) {
          const courseNames = response.data.results.map(
            (course) => course.course.name
          );
          setCourseNames(courseNames);
        }
        setLoader(true);
      } else {
        toast.error("Failed to fetch Course data");
        setLoader(false);
      }
    } catch (error) {
      toast.error("Error fetching userCourse data: " + error.message);
      setLoader(false);
    }
  };

  const findUserCourse = async (searchKey) => {
    // setLoader(false);
    try {
      let response;
      if (status) {
        response = await searchUserCourses(header, searchKey, status);
      } else {
        response = await searchUserCourses(header, searchKey);
      }

      if (response.status === 200) {
        setSelectedCourses(response.data.results);
        setLoader(true);
      } else {
        toast.error("Failed to Search Course data");
        setLoader(false);
      }
    } catch (error) {
      toast.error("Error searching Course data: " + error.message);
      setLoader(false);
    }
  };

  const handleCourseSelect = (courseName) => {
    findUserCourse(courseName);
    setSearchText(courseName);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleAdminButtonClick = () => {
    navigate("/learning/admin/");
  };
  const handleHamburgerNav = () => {
    setOpenMobileNav(true);
  };

  return (
    <>
      {!loader ? (
        <AppLoader />
      ) : (
        <div className="app-course-selector">
          <div className="app-mobile-header">
            <div
              className="app-mobile-header-hamburger"
              onClick={handleHamburgerNav}
            >
              <span className="app-mobile-header-hamburger-bar"></span>
              <span className="app-mobile-header-hamburger-bar"></span>
              <span className="app-mobile-header-hamburger-bar"></span>
            </div>
            <div className="app-mobile-header-logo">
              <div className="app-mobile-header-logo-img">
                <img src={logo} alt="logo"></img>
              </div>
            </div>
            <div className="app-mobile-header-sidebar-button">
              <img src={avatar} alt="userimage"></img>
              {/* <div className='app-mobile-header-sidebar-button-triangle'></div> */}
            </div>
          </div>
          <AppNavbar
            setOpenMobileNavbar={(e) => setOpenMobileNav(e)}
            navbarData={navbarData}
            deviceWidth={deviceWidth}
            openMobileNav={openMobileNav}
          ></AppNavbar>

          <div className="app-course-selector-body">
            <div className="app-course-selector-body-user">
              {state.is_admin === true && (
                <button onClick={handleAdminButtonClick}>Admin</button>
              )}
              <img src={avatar} alt="userimage"></img>
            </div>
            <div className="app-course-selector-body-title">
              <div className="app-course-selector-body-title-text">
                Select Your Course
              </div>
              <div className="app-course-selector-body-title-search_box">
                <SearchBox
                  searchData={courseNames}
                  onValueClick={handleCourseSelect}
                  onClearSearch={handleClearSearch}
                />
              </div>
            </div>
            <AppStartCourse
              selectedCourses={selectedCourses}
              isStartButton={true}
              header={header}
              setStatus={setStatus}
              status={status}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CourseSelector;
