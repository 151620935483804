import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./SubjectContent.scss";
import { updateChapterProgress } from "../services/api.services";
import toast from "react-hot-toast";
import parse from "html-react-parser";
import AppVideoPlayer from "../components/AppVideoPlayer";
import AppPdfViewer from "../components/AppPdfViewer";
import ChapterQuiz from "../components/ChapterQuiz";
import ChapterAccordion from "../components/ChapterAccordion";
import CardCarousal from "../components/CardCarousal";
import { FaArrowLeft } from "react-icons/fa";
import '../../../theme/_buttons.scss';

const SubjectContent = ({
  initiateChapterProgress,
  handleChapterDetailSelector,
  chapterDetails,
  setSelectedChapter,
  moduleChapters,
  header,
  customKey,
  setKey,
  routeData,
}) => {
  const [currentChapterIndex, setCurrentChapterIndex] = useState(0);
  const [showQuiz, setShowQuiz] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (chapterDetails && chapterDetails.id) {
      const index = moduleChapters.findIndex(
        (chapter) => chapter.id === chapterDetails.id
      );
      if (index !== -1) {
        setCurrentChapterIndex(index);
      }
    }
  }, [chapterDetails, moduleChapters]);

  const handleNextChapter = () => {
    if (currentChapterIndex < moduleChapters.length - 1) {
      setCurrentChapterIndex(currentChapterIndex + 1);
      setSelectedChapter(moduleChapters[currentChapterIndex + 1]);
      initiateChapterProgress(moduleChapters[currentChapterIndex + 1].id);
      setShowQuiz(false);
    } else {
      // Handle the case for the last chapter
      toast.success("Module Complete!");
      setSelectedChapter(null);
      setKey(!customKey)
      navigate(routeData.nextModuleRoute);
      localStorage.setItem('selectedNavTab', routeData.navbarIndex)
    }
  };


  const handleOnBack = () => {
    setShowQuiz(false);
    handleChapterDetailSelector(moduleChapters[currentChapterIndex]);
  };

  const handleOnNextChapterClick = () => {
    completeChapterProgress(chapterDetails.id);
  };

  const setChapterProgress = async (chapter_id, progress = 75) => {
    const payload = { progress };
    await updateChapterProgress(header, chapter_id, payload)
      .then((resp) => {
        if (resp.data.isSuccess) {
          // console.log(`Progress Updated to ${progress}%!`);
        } else {
          // console.log("Already in progress!");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };

  const completeChapterProgress = async (chapter_id) => {
    const payload = { progress: 100, is_complete: "True" };
    await updateChapterProgress(header, chapter_id, payload)
      .then((resp) => {
        if (resp.data.isSuccess) {
          toast.success("Chapter Completed!");
        } else {
          toast.error("Something went wrong!");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };

  const handleQuizClick = () => {
    setShowQuiz(true);
    setChapterProgress(chapterDetails.id);
  };

  return (
    <div className="app-subcontent">
      {chapterDetails !== null && (
        <div className="app-subcontent_body">
          <div className="app-subcontent_body-heading">
            {/* Here we are using the potion field to hge the chapter number In future we need to add a field 'chapter number' in backend if needed */}
            Chapter {chapterDetails.position}
          </div>
          <div className="app-subcontent_body-title">{chapterDetails.name}</div>

          <div className="app-subcontent_body-content">
            {chapterDetails.documents
              .sort((a, b) => a.position - b.position)
              .map((data) => {
                return (
                  <div
                    key={data.id}
                    className="app-subcontent_body-content-chapter"
                  >
                    {data.format === 1 && (
                      <div className="app-subcontent_body-content-chapter-text">
                        {data.content}
                      </div>
                    )}
                    {data.format === 2 && (
                      <div className="app-subcontent_body-content-chapter-html">
                        {parse(data.content)}
                      </div>
                    )}
                    {data.format === 3 && (
                      <div className="app-subcontent_body-content-chapter-video">
                        <AppVideoPlayer contentURL={data.file} />
                      </div>
                    )}
                    {data.format === 4 && (
                      <div className="app-subcontent_body-content-chapter-pdf">
                        <AppPdfViewer subjectfileUrl={data.file} />
                      </div>
                    )}
                    {data.format === 5 && (
                      <div className="app-subcontent_body-content-chapter-img">
                        <img src={data.file} alt={data.name}></img>
                      </div>
                    )}
                    {data.format === 6 && (
                      <>
                        <div className="app-subcontent_body-content-chapter-subheading">
                          {data.name}
                        </div>
                        <div className="app-subcontent_body-content-chapter-carousal">
                          <CardCarousal cards={data.extra_data} />
                        </div>
                      </>
                    )}
                    {data.format === 7 && (
                      <>
                        <div className="app-subcontent_body-content-chapter-subheading">
                          {data.name}
                        </div>
                        <div className="app-subcontent_body-content-chapter-accordion">
                          <ChapterAccordion sections={data.extra_data} />
                        </div>
                      </>
                    )}
                  </div>
                );
              })}

            {/* Conditionally render based on chapterDetails.quiz_data */}
            {chapterDetails.quiz_data === null && (
              <div className="app-subcontent_body-next-chapter">
                <button className="back-button" onClick={()=>{
                      setSelectedChapter(null);
                      setKey(!customKey);
                    }}><FaArrowLeft  />Back</button>
                {currentChapterIndex !== moduleChapters.length - 1 ? (
                  <button className="primary-button"
                    onClick={() => {
                      handleOnNextChapterClick();
                      handleNextChapter();
                    }}
                  >
                    {" "}
                    Next Chapter{" "}
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      handleOnNextChapterClick();
                      handleNextChapter();
                    }}
                  >
                    {" "}
                    Finish{" "}
                  </button>
                )}
              </div>
            )}

            {/* Conditionally render based on showQuiz and chapterDetails.quiz_data */}
            {chapterDetails.quiz_data !== null && (
              <>
                {showQuiz ? (
                  <ChapterQuiz
                    header={header}
                    chapterId={chapterDetails.id}
                    quizData={chapterDetails.quiz_data}
                    lastAttemptData={chapterDetails.last_attempt_data}
                    onBack={handleOnBack}
                    handleNextChapter={handleNextChapter}
                    progress={chapterDetails.progress}
                    setSelectedChapter={setSelectedChapter}
                  />
                ) : (
                  <div className="app-subcontent_body-quiz">
                    <button className="back-button" onClick={()=>{
                      setSelectedChapter(null);
                      setKey(!customKey);
                    }}><FaArrowLeft  />Back</button>
                    <button className="primary-button" onClick={handleQuizClick}>
                      {chapterDetails.last_attempt_data !== null
                        ? "View Result"
                        : "Take Test"}
                    </button>
                  </div>
                )}
              </>
            )}

            {/* <div style={{ textAlign: 'center' , paddingTop: '40px' }}>
                <iframe width="720" height="500" title="youtube"
                    src="https://www.youtube.com/embed/o_MhQKn-LZU">
                </iframe>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default SubjectContent;
