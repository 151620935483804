import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../App";
import toast from "react-hot-toast";
import "./AdminClasses.scss";
import CreateClassPopup from "../components/CreateClassPopup";
import DeleteClassPopup from "../components/DeleteClassPopup";
import {
  getAdminChapterData,
  getAllCourses,
  getAllModules,
} from "../services/api.services";
import { FaPencilAlt } from "react-icons/fa";

const AdminClasses = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [chapterData, setChapterData] = useState([]);
  const [allCourseData, setAllCourseData] = useState([]);
  const [allModuleData, setAllModuleData] = useState([]);

  useEffect(() => {
    getChapterData();
    getCourseData();
    getModuleData();
  }, []);

  let header = {
    Authorization: "Token " + state.token,
  };

  const getChapterData = async () => {
    try {
      const response = await getAdminChapterData(header);
      if (response.data.isSuccess) {
        setChapterData(response.data.dataInfo);
      } else {
        toast.error("Failed to fetch chapter data");
      }
    } catch (error) {
      toast.error("Error fetching chapter data: " + error.message);
    }
  };
  const getCourseData = async () => {
    try {
      const response = await getAllCourses(header);
      if (response.data.isSuccess) {
        setAllCourseData(response.data.dataInfo);
      } else {
        toast.error("Failed to fetch course data");
      }
    } catch (error) {
      toast.error("Error fetching course data: " + error.message);
    }
  };
  const getModuleData = async () => {
    try {
      const response = await getAllModules(header);
      if (response.data.isSuccess) {
        setAllModuleData(response.data.dataInfo);
      } else {
        toast.error("Failed to fetch module data");
      }
    } catch (error) {
      toast.error("Error fetching module data: " + error.message);
    }
  };
  const refreshChapterData = () => {
    getChapterData();
  };

  return (
    <div className="admin-classes">
      <div className="admin-classes-title">
        <div className="admin-classes-title-text">Classes</div>
        <CreateClassPopup
          header={header}
          allCourseData={allCourseData}
          allModuleData={allModuleData}
          onSuccess={refreshChapterData}
        />
      </div>
      <div className="admin-classes-body">
        <table className="admin-classes-body-table">
          <div className="admin-classes-body-table-head">
            <div className="admin-classes-body-table-head-item">Class Name</div>
            <div className="admin-classes-body-table-head-item">Module</div>
            <div className="admin-classes-body-table-head-item">Course</div>
            <div className="admin-classes-body-table-head-item">Action</div>
          </div>
          <div className="admin-classes-body-table-body">
            {chapterData.map((data, index) => (
              <div className="admin-classes-body-table-body-row" key={index}>
                <div className="admin-classes-body-table-body-row-item">
                  <div className="admin-classes-body-table-body-row-item-img">
                    <img src="" alt="icon" />
                  </div>
                  <div className="admin-classes-body-table-body-row-item-text">
                    {data.name}
                  </div>
                </div>
                <div className="admin-classes-body-table-body-row-item">
                  {data.module_name}
                </div>
                <div className="admin-classes-body-table-body-row-item">
                  {data.course_name}
                </div>
                <div className="admin-classes-body-table-body-row-item">
                  <div className="admin-classes-body-table-body-row-item-icons">
                    <FaPencilAlt className="icon-pencil" />
                    <DeleteClassPopup
                      className="icon-trash"
                      header={header}
                      classID={data.id}
                      name={data.name}
                      moduleName={data.module_name}
                      onSuccess={refreshChapterData}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </table>
      </div>
    </div>
  );
};

export default AdminClasses;
