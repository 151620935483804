import React, { useState } from "react";
import "./AdminHeader.scss";
import logo from "../../images/icons/logo.png";
import tabLogo from "../../images/icons/mobile_logo.png";
import user_avatar from "../../images/icons/user_avatar.png";
import { useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import superAppIcon from '../../images/icons/admin/superapp/superapp.png';
import complianceApp from '../../images/icons/admin/superapp/compliance.png';
import learningApp from '../../images/icons/admin/superapp/learning.png';
import policyApp from '../../images/icons/admin/superapp/policy.png';
import riskApp from '../../images/icons/admin/superapp/risk.png';

const AdminHeader = ({ toggleSidebar, creditBalance, isSubscription }) => {
  const [superAppIsOpen, setSuperAppIsOpen] = useState(false)
  const navigate = useNavigate();

  const toggleSuperAppButton = () => {
    if(!superAppIsOpen){
      document.getElementById('super_app_button').classList.add('active');
      document.getElementById('supper_app_container').style.display = 'block';
    }
    else{
      document.getElementById('super_app_button').classList.remove('active');
      document.getElementById('supper_app_container').style.display = 'none';
    }
    setSuperAppIsOpen(!superAppIsOpen)
    
  }

  return (
    <div className="header">
      <FaBars className="header-hambuger" onClick={() => toggleSidebar()} />
      <div className="header-logo">
        <div className="header-logo-img">
          <img src={logo} alt="logo" />
        </div>
        {/* <div className="header-logo-tab">
          <img src={tabLogo} alt="logo" />
        </div> */}
      </div>
      <div className="header-user">
        {!isSubscription && (
          <div className="header-user-credits">Credits : {creditBalance}</div>
        )}
        <div className="header-user-superapp">
          <div id='super_app_button' className="header-user-superapp-button" onClick={() => toggleSuperAppButton()}>
            <img src={superAppIcon} alt="super app"></img>
          </div>
          <div id="supper_app_container" className="header-user-superapp-container">
            <div className="header-user-superapp-container-row">
              <div className="header-user-superapp-container-row-item" onClick={()=> navigate('/learning/admin/')}>
                <div className="header-user-superapp-container-row-item-icon" style={{'background-color': '#CCF4F7'}}>
                  <img src={learningApp} alt="learning app"></img>
                </div>
                <div className="header-user-superapp-container-row-item-title">
                  Learning
                </div>
              </div>
              <div className="header-user-superapp-container-row-item" onClick={()=> navigate('/compliance/admin/')}>
                <div className="header-user-superapp-container-row-item-icon" style={{'background-color': '#CCF7D8'}}>
                  <img src={complianceApp} alt="compliance app"></img>
                </div>
                <div className="header-user-superapp-container-row-item-title">
                  Compliance
                </div>
              </div>
            </div>
            <div className="header-user-superapp-container-row">
              <div className="header-user-superapp-container-row-item" onClick={()=> navigate('/risk/admin/')}>
                <div className="header-user-superapp-container-row-item-icon" style={{'background-color': '#FBE7FF'}}>
                  <img src={riskApp} alt="risk app"></img>
                </div>
                <div className="header-user-superapp-container-row-item-title">
                  Risk
                </div>
              </div>
              <div className="header-user-superapp-container-row-item" onClick={()=> navigate('/policy/admin/')}>
                <div className="header-user-superapp-container-row-item-icon" style={{'background-color': '#FFF0D3'}}>
                  <img src={policyApp} alt="policy app"></img>
                </div>
                <div className="header-user-superapp-container-row-item-title">
                  Policy
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="header-user-avatar">
          <div className="header-user-avatar-name">Admin</div>
          <div className="header-user-avatar-image">
            <img src={user_avatar} alt="picture" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
