import React from 'react'

const AdminDashboard = () => {
  return (
    <div>
      Compliance
    </div>
  )
}

export default AdminDashboard
