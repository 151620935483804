import { createContext, useContext, useState, useEffect } from 'react';
import { getUserCreditDetails } from "../services/api.services";
import { AuthContext } from "../../../App";
import toast from "react-hot-toast";

const CreditContext = createContext();

export const CreditProvider = ({ children }) => {
  const { state } = useContext(AuthContext);
  const [creditBalance, setCreditBalance] = useState(null);
  const [isSubscription, setIsSubscription] = useState(false);

  const header = {
    Authorization: "Token " + state.token,
  };

  const fetchCreditDetails = async () => {
    try {
      const response = await getUserCreditDetails(header);
      if (response.data.isSuccess) {
        const { user_credit_balance, is_subscription } = response.data.dataInfo;
        setIsSubscription(is_subscription);
        if (is_subscription) {
          setCreditBalance(null);
        } else {
          setCreditBalance(user_credit_balance);
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching credit details:", error);
    }
  };

  useEffect(() => {
    fetchCreditDetails();
  }, []);

  return (
    <CreditContext.Provider value={{ creditBalance, isSubscription, fetchCreditDetails }}>
      {children}
    </CreditContext.Provider>
  );
};

export const useCredit = () => useContext(CreditContext);
