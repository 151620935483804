import React, { useState, useRef, useEffect } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import "./SearchBox.scss";

const SearchBox = ({ 
  searchData, 
  onValueClick, 
  onClearSearch, 
  onSearch,  
  useDynamicSearch = false 
}) => {
  const [searchText, setSearchText] = useState("");
  const [filteredSearchData, setFilteredSearchData] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState(-1);
  const searchInputRef = useRef(null);
  const suggestionContainerRef = useRef(null);

  useEffect(() => {
    if (!useDynamicSearch) {
      setFilteredSearchData(searchData); 
    }
  }, [searchData, useDynamicSearch]);

  const handleSearch = async (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);
    if (searchText) {
      if (useDynamicSearch && onSearch) {
        // If dynamic search is enabled, trigger the onSearch callback
        const fetchedData = await onSearch(searchText); // Expect onSearch to return new search data
        setFilteredSearchData(fetchedData || []);
      } else {
        // Static search logic
        const filteredData = searchData.filter((item) =>
          item.toLowerCase().includes(searchText)
        );
        setFilteredSearchData(filteredData);
      }
      setSearchActive(true);
      setActiveSuggestion(-1);
    } else {
      setFilteredSearchData([]);
      setSearchActive(false);
      setActiveSuggestion(-1);
      onClearSearch();
    }
  };

  const handleValueClick = (value) => {
    onValueClick(value);
    setSearchText(value);
    setSearchActive(false);
    setActiveSuggestion(-1);
  };

  const handleKeyDown = (event) => {
    if (filteredSearchData.length === 0) return;

    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        setActiveSuggestion((prevIndex) =>
          prevIndex < filteredSearchData.length - 1 ? prevIndex + 1 : prevIndex
        );
        break;
      case "ArrowUp":
        event.preventDefault();
        setActiveSuggestion((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
        break;
      case "Enter":
        if (activeSuggestion !== -1) {
          handleValueClick(filteredSearchData[activeSuggestion]);
        }
        break;
      default:
        break;
    }

    if (suggestionContainerRef.current && activeSuggestion !== -1) {
      const activeElement = suggestionContainerRef.current.children[
        activeSuggestion
      ];
      if (activeElement) {
        activeElement.scrollIntoView({
          behavior: "auto",
          block: "center",
        });
      }
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      setSearchActive(false);
    }, 200); 
    setActiveSuggestion(-1);
  };

  const handleFocus = () => {
    setSearchActive(true);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredSearchData([]);
    setSearchActive(false);
    setActiveSuggestion(-1);
    onClearSearch();
  };

  return (
    <div className="search-box">
      <FaSearch />
      <div className="search-box-input-wrapper">
        <input
          className="search-box-input-wrapper-input"
          type="text"
          placeholder="Search"
          value={searchText}
          onChange={handleSearch}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={searchInputRef}
        />
      </div>
      {searchActive && filteredSearchData.length > 0 && (
        <div className="search-box-suggestions">
          <div className="search-box-suggestions-container" ref={suggestionContainerRef}>
            {filteredSearchData.map((item, index) => (
              <div
                key={index}
                className={`search-box-suggestions-container-suggestion ${
                  index === activeSuggestion ? "active" : ""
                }`}
                onMouseDown={() => handleValueClick(item)} 
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      )}
      {searchText && (
        <FaTimes 
          style={{ color: "grey", cursor: "pointer" }}
          onClick={handleClearSearch}
        />
      )}
    </div>
  );
};

export default SearchBox;
