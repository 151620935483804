import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../App";
import toast from "react-hot-toast";
import "./AdminTrainees.scss";
import AddTraineePopup from "../components/AddTraineePopup";
import DeleteTraineePopup from "../components/DeleteTraineePopup";
import BlockTraineePopup from "../components/BlockTraineePopup";
import UnblockTraineePopup from "../components/UnblockTraineePopup";
import DynamicContainer from "../components/DynamicContainer";
import FileUploadPopup from "../components/FileUploadPopup";
import AdminCustomCheckbox from '../components/AdminCustomCheckbox';
import { getTraineeData, getDynamicContainerData, getUploadTaskStatus } from "../services/api.services";


const AdminTrainees = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [traineeData, setTraineeData] = useState([]);
  const [selectedTraineeIds, setSelectedTraineeIds] = useState([]);
  const [isAnyCheckboxClicked, setIsAnyCheckboxClicked] = useState(false);
  const [taskId, setTaskId] = useState(localStorage.getItem("taskId") || "")
  const [taskStatus, setTaskStatus] = useState("");
  const [fileData, setFileData] = useState({});
  const [containerData, setContainerData] = useState({});
  const [isFileUpload, setIsFileUpload] = useState(!! taskId || false);
  const navigate = useNavigate();

  // Add these useState for the CustomCheckbox, response data
  const [checkedData, setCheckedData] = useState([])
  // const [responseData, setResponseData] = useState([])
  useEffect(() => {
    getTraineeDetails();
    fetchDynamicContainerData();
    if (taskId) {
      fetchTaskStatus(taskId);
    }
  }, [isFileUpload]);

  let header = {
    Authorization: "Token " + state.token,
  };

  const getTraineeDetails = async () => {
    try {
      const response = await getTraineeData(header);
      if (response.status == 200) {
        setTraineeData(response.data.results);
      } else {
        toast.error("Failed to fetch trainee data");
      }
    } catch (error) {
      toast.error("Error fetching trainee data: " + error.message);
    }
  };

  const fetchTaskStatus = async (taskId) => {
    try {
      const response = await getUploadTaskStatus(header, taskId);
      if (response.isSuccess) {
        const status = response.dataInfo.status;
        setTaskStatus(status);
        setFileData({
          fileName: response.dataInfo.filename,
          date: new Date(response.dataInfo.created_on).toLocaleDateString(),
          ...(status === "error" && { fileUrl: response.dataInfo.file }),
        });
      } else {
        setTaskStatus("");
      }
    } catch (error) {
      setTaskStatus("");
    }
  };

  const fetchDynamicContainerData = async () => {
    try {
      const response = await getDynamicContainerData(header);
      if (response.isSuccess) {
        setContainerData(response.dataInfo);
      } else {
        setContainerData(null);
      }
    } catch (error) {
      setContainerData(null);
    }
  };


  const handleCheckboxClick = (traineeId) => {
    setIsAnyCheckboxClicked(true);
    const index = selectedTraineeIds.indexOf(traineeId);
    if (index === -1) {
      setSelectedTraineeIds([...selectedTraineeIds, traineeId]);
    } else {
      setSelectedTraineeIds(
        selectedTraineeIds.filter((id) => id !== traineeId)
      );
    }
  };

  const handleAllCheckboxClick = () => {
    setIsAnyCheckboxClicked(true);
    if (selectedTraineeIds.length === traineeData.length) {
      setSelectedTraineeIds([]);
    } else {
      setSelectedTraineeIds(traineeData.map((trainee) => trainee.id));
    }
  };

  const refreshTraineeData = () => {
    setSelectedTraineeIds([]);
    // handleAllCheckboxClick();
    getTraineeDetails();
  };

  const handleEnrollCourses = () => {
    if (selectedTraineeIds.length !== 0) {
      const inactiveUsers = selectedTraineeIds.filter((id) => {
        const trainee = traineeData.find((trainee) => trainee.id === id);
        return trainee && trainee.status !== "Active";
      });
  
      if (inactiveUsers.length > 0) {
        toast.error("You can only enroll courses for active users.");
      } else {
        navigate("/learning/admin/enroll-courses", { state: selectedTraineeIds });
      }
    }
  };

  const handleClose = () => {
    setTaskId(null);
    setIsFileUpload(false)
    localStorage.removeItem("taskId");
  };
  const handleTraineeDetails = (trainee) => {
    navigate("details", {state: trainee})
  }

  return (
    <div className="admin-trainees">
      <div className="admin-trainees-title">
        <div className="admin-trainees-title-text">Trainees</div>
        <div className="admin-trainees-title-buttons">
          <button className={`admin-trainees-title-buttons-button ${
                selectedTraineeIds.length === 0 ? "" : "enabled"
              }`} onClick={handleEnrollCourses}>
            Enroll Courses
          </button>
          <FileUploadPopup header={header} setIsFileUpload={setIsFileUpload} setTaskId={setTaskId}/>
          <AddTraineePopup header={header} onSuccess={refreshTraineeData} />
        </div>
      </div>
      <DynamicContainer
        isFileUpload={isFileUpload}
        taskStatus={taskStatus}
        fileData={fileData}
        containerData={containerData}
        onClose={handleClose}
      />
      <div className="admin-trainees-body">
        <div className="admin-trainees-body-table">
          <div className="admin-trainees-body-table-top">
            <div className="admin-trainees-body-table-top-selected">
              {selectedTraineeIds.length > 0 &&
                `${selectedTraineeIds.length} Selected`}
            </div>
            <div
              className={`admin-trainees-body-table-top-actions ${
                selectedTraineeIds.length === 0 ? "disabled" : ""
              }`}
            >
              {/* <div className="admin-trainees-body-table-top-actions"> */}
              {/* <div className="admin-trainees-body-table-top-actions-button ">
                <div className="admin-trainees-body-table-top-actions-button-icon">
                  <FaCheck />
                </div>
                <div className="admin-trainees-body-table-top-actions-button-text">
                  Active
                </div>
              </div>
              <div className="admin-trainees-body-table-top-actions-button">
                <div className="admin-trainees-body-table-top-actions-button-icon">
                  <FaBan />
                </div>
                <div className="admin-trainees-body-table-top-actions-button-text">
                  Inactive
                </div>
              </div> */}
              {/* <div className="admin-trainees-body-table-top-actions-button">
                <div className="admin-trainees-body-table-top-actions-button-icon">
                  <FaRegTrashAlt />
                </div>
                <div className="admin-trainees-body-table-top-actions-button-text">
                  Delete
                </div>
              </div> */}
              <UnblockTraineePopup idArray={selectedTraineeIds} header={header} onSuccess={refreshTraineeData} />
              <BlockTraineePopup idArray={selectedTraineeIds} header={header} onSuccess={refreshTraineeData} />
              <DeleteTraineePopup idArray={selectedTraineeIds} header={header} onSuccess={refreshTraineeData} />
            </div>
          </div>
          <div className="admin-trainees-body-table-head">
            <div className="admin-trainees-body-table-head-item">
              <AdminCustomCheckbox selectAll={true} setCheckedData={setSelectedTraineeIds} checkedData={selectedTraineeIds} responseData={traineeData}></AdminCustomCheckbox>
              <span className="admin-trainees-body-table-head-item-label">User</span>
            </div>
            <div className="admin-trainees-body-table-head-item">
              Enrolled Courses
            </div>
            <div className="admin-trainees-body-table-head-item">Status</div>
          </div>
          <div className="admin-trainees-body-table-body">
            {traineeData.map((data, index) => (
              <div className="admin-trainees-body-table-body-row" key={index}>
                <AdminCustomCheckbox selectAll={false} data_id={data.id} setCheckedData={setSelectedTraineeIds} checkedData={selectedTraineeIds}></AdminCustomCheckbox>
                <div className="admin-trainees-body-table-body-row-value">
                  <div className="admin-trainees-body-table-body-row-value-item">
                    <div className="admin-trainees-body-table-body-row-value-item-icon">
                      {data.short_name}
                    </div>
                    <div className="admin-trainees-body-table-body-row-value-item-text">
                      <div className="admin-trainees-body-table-body-row-value-item-text-name">
                        <span onClick={() => handleTraineeDetails(data.id)}>{data.full_name}</span>
                      </div>
                      <div className="admin-trainees-body-table-body-row-value-item-text-email">
                        {data.email}
                      </div>
                    </div>
                  </div>
                  <div className="admin-trainees-body-table-body-row-value-item">
                    {data.courses_enrolled}
                  </div>
                  <div className="admin-trainees-body-table-body-row-value-item">
                    <div
                      className="status-text"
                      style={{
                        color:
                          data.status === "Active"
                            ? "rgb(55, 209, 174)"
                            : "rgb(253, 143, 38)",
                      }}
                    >
                      {data.status}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTrainees;
