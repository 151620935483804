import { createContext, useReducer, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ResetPassword from "./learning/user/views/ResetPassword";
import CourseSelector from "./learning/user/views/CourseSelector";
import Login from "./learning/user/views/Login";
import { Toaster } from "react-hot-toast";
import Dashboard from "./learning/user/views/Dashboard";
import LearningAdminDashboard from "./learning/admin/views/AdminDashboard";
import PricingPlanSelector from "./learning/admin/views/PricingPlanSelector";
import StripeSetupSession from "./learning/user/components/StripeSetupSession";
import StripeSetupSuccessPage from "./learning/admin/views/StripeSetupSuccessPage";
import AppLearningRouter from "./learning/AppLearningRouter";
import AppComplianceRouter from "./compliance/AppComplianceRouter";
import ComplianceAdminDashboard from './compliance/admin/views/AdminDashboard';

export const AuthContext = createContext();
sessionStorage.clear();
// localStorage.clear();
const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  is_admin: false,
  is_organisation_pricing_plan: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("is_admin", action.payload.is_admin);
      localStorage.setItem(
        "is_organisation_pricing_plan",
        action.payload.is_organisation_pricing_plan
      );
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        is_admin: action.payload.is_admin,
        is_organisation_pricing_plan: action.payload.is_organisation_pricing_plan,
      };
    case "UPDATE_PRICING_PLAN":
      localStorage.setItem(
        "is_organisation_pricing_plan",
        action.payload.is_organisation_pricing_plan
      );
      return {
        ...state,
        is_organisation_pricing_plan: action.payload.is_organisation_pricing_plan,
      };
    case "LOGOUT":
      localStorage.clear();
      window.location.reload();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
        is_admin: false,
        is_organisation_pricing_plan: false,
      };
    default:
      return state;
  }
};

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  // this below code is used to control the zoom rate of the app
  // useEffect(() => {
  //   document.body.style.zoom = "90%";
  // }, []);
  if (localStorage.getItem("token")) {
    state.isAuthenticated = true;
    state.token = localStorage.getItem("token");
    state.is_admin = JSON.parse(localStorage.getItem("is_admin"));
    state.is_organisation_pricing_plan = JSON.parse(localStorage.getItem(
      "is_organisation_pricing_plan"
    ));
  }

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      <div className="app">
        <Toaster />
        <Router>
          <Routes>
            {state.isAuthenticated ? (
              <>
                {state.is_admin ? (
                  state.is_organisation_pricing_plan ? (
                    <>
                      <Route path="/" element={<CourseSelector />} />
                      <Route path="/learning" element={<AppLearningRouter />}>
                        <Route path="admin/*" element={<LearningAdminDashboard />} />
                      </Route>
                      <Route path="/compliance" element={<AppComplianceRouter />}>
                        <Route path="admin/*" element={<ComplianceAdminDashboard />} />
                      </Route>
                      <Route path="/policy" element={<AppLearningRouter />}>
                        <Route path="admin/*" element={<LearningAdminDashboard />} />
                      </Route>
                      <Route path="/risk" element={<AppLearningRouter />}>
                        <Route path="admin/*" element={<LearningAdminDashboard />} />
                      </Route>
                      <Route path="/:course/*" element={<Dashboard />} />
                      <Route path="/reset-password/:token" element={<ResetPassword />} />
                      <Route path="/stripe_gateway_success/*" element={<StripeSetupSuccessPage />} />
                      <Route path="*" element={<Navigate to="/learning/admin/*" />} />
                    </>
                  ) : (
                    <>
                      <Route path="/" element={<Login />} />
                      <Route path="/pricing_plan_selector" element={<PricingPlanSelector />} />
                      <Route path="/stripe_gateway" element={<StripeSetupSession />} />
                      <Route path="/stripe_gateway_success/*" element={<StripeSetupSuccessPage />} />
                      <Route path="/reset-password/:token" element={<ResetPassword />} />
                      <Route path="*" element={<Navigate to="/" />} />
                    </>
                  )
                ) : (
                  <>
                    <Route path="/" element={<CourseSelector />} />
                    <Route path="/:course/*" element={<Dashboard />} />
                    <Route path="/reset-password/:token" element={<ResetPassword />} />
                    <Route path="*" element={<Navigate to="/" />} />
                  </>
                )}
              </>
            ) : (
              <>
                <Route path="/" element={<Login />} />
                <Route path="*" element={<Navigate to="/" />} />
              </>
            )}
            <Route path="/reset-password/:token" element={<ResetPassword />} />
          </Routes>
        </Router>
      </div>
    </AuthContext.Provider>
  );
}

export default App;
