import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminCourses from "../views/AdminCourse";
import AdminModules from "../views/AdminModules";
import AdminClasses from "../views/AdminClasses";
import AdminTrainees from "../views/AdminTrainees";
import AdminCredits from "../views/AdminCredits";
import AdminCheckout from "../views/AdminCheckout";
import AdminPaymentSuccess from "./AdminPaymentSuccess";
import AdminEnrollCourses from "../views/AdminEnrollCourses";
import AdminDashboardElements from "../views/AdminDashboardElements";
import AdminTraineeDetails from "../views/AdminTraineeDetails";
import AdminRequests from "../views/AdminRequests";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<AdminDashboardElements />} />
      <Route path="courses" element={<AdminCourses />} />
      <Route path="modules" element={<AdminModules />} />
      <Route path="classes" element={<AdminClasses />} />
      <Route path="trainees" element={<AdminTrainees />} />
      <Route path="credits" element={<AdminCredits />} />
      <Route path="checkout" element={<AdminCheckout />} />
      <Route path="requests" element={<AdminRequests />} />
      <Route path="/payment_success/*" element={<AdminPaymentSuccess />} />
      <Route path="enroll-courses" element={<AdminEnrollCourses />} />
      <Route path="trainees/details" element={<AdminTraineeDetails />} />
    </Routes>
  );
};

export default AdminRoutes;
