import React from 'react';
import { Outlet } from "react-router-dom";

const AppComplianceRouter = () => {
  return (
    <Outlet />
  )
}

export default AppComplianceRouter
