import React, { useState } from "react";
import "./CreditSlider.scss";

const CreditSlider = ({ tierPricing }) => {
  // Determine the min and max values based on the tier_pricing data
  const tiers = Object.values(tierPricing);
  const minCredits = Math.min(...tiers.map((t) => t.number_of_credits));
  const maxCredits = Math.max(...tiers.map((t) => t.number_of_credits));

  // Set initial value to the mid-range credit
  const [value, setValue] = useState(minCredits);

  // Handle slider input change
  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  // Find the tier corresponding to the current slider value
  const currentTier =
    tiers.find((t) => t.number_of_credits >= value) || tiers[0];

  return (
    <div className="slide-container">
      <div className="slide-container-cost">£{currentTier.credit_cost.toFixed(2)}</div>

      <div className="slide-container-credits">{`${value} credits`}</div>

      <input
        type="range"
        min={minCredits}
        max={maxCredits}
        value={value}
        className="slide-container-slider"
        id="creditRange"
        onChange={handleInputChange}
      />
      <div className="slide-container-help_text">SLIDE TO SEE ESSENTIAL PRICING</div>
    </div>
  );
};

export default CreditSlider;
