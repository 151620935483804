import React from 'react';
import circle from '../../images/loader/circle.png';
import oldMan from '../../images/loader/old-man.png';
import './AppLoader.scss';

const AppLoader = () => {
  return (
    <div className='app-loader'>
        <div className='app-loader-circle'>
            <img src={circle} alt='loader'></img>
        </div>
        <div className='app-loader-oldman'>
            <img src={oldMan} alt='loader'></img>
        </div>
    </div>
  )
}

export default AppLoader
