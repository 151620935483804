import React from 'react';
import {Outlet} from "react-router-dom";

const AppLearningRouter = () => {
  return (
       <Outlet />
  )
}

export default AppLearningRouter
