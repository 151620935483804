import React, { useState, useEffect, useRef, useContext } from "react";
import {
  FaPlus,
  FaTrashAlt,
  FaCloudUploadAlt,
  FaFileExcel,
  FaRegTrashAlt,
} from "react-icons/fa";
import toast from "react-hot-toast";
import PopupModal from "../components/PopupModal";
import SearchBox from "../../user/components/SearchBox";
import AppPagination from "../components/AppPagination";
import "../../../theme/_buttons.scss";
import { AuthContext } from "../../../App";
import AdminCustomCheckbox from "../components/AdminCustomCheckbox";
import {
  createRequest,
  getRequests,
  removeRequest,
  searchRequests,
} from "../services/api.services";
import { getUserCourses } from "../../user/services/api.services";
import "./AdminRequests.scss";

const AdminRequests = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState("course");
  const [isOpen, setIsOpen] = useState(false);
  const [isRemove, setIsRemove] = useState(false);
  const [key, setKey] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [checkedData, setCheckedData] = useState([]);
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadError, setUploadError] = useState("");
  const [courseInfoText, setCourseInfoText] = useState("");
  const fileInputRef = useRef(null);
  const itemClass = checkedData.length > 0 ? "active" : "inactive";
  const [courseNames, setCourseNames] = useState([]);
  const [requestNames, setRequestNames] = useState([]);
  const [searchKey, setSearchKey] = useState("");

  var recordsPerPage = 5;
  const [pageNo, setPageNo] = useState(1);
  const [dataCount, setDataCount] = useState(0);

  useEffect(() => {
    fetchUserCourses();
  }, []);

  const fetchUserCourses = async () => {
    try {
      let response;
      response = await getUserCourses(header);
      if (response.status === 200) {
        const courseNames = response.data.results.map(
          (course) => course.course.name
        );
        setCourseNames(courseNames);
      } else {
        toast.error("Failed to fetch Course data");
      }
    } catch (error) {
      toast.error("Error fetching userCourse data: " + error.message);
    }
  };

  let header = {
    Authorization: "Token " + state.token,
  };
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setCheckedData([]);
    setCourseInfoText("");
    setIsOpen(false);
    setPageNo(1)
  };

  const openPopup = (type) => {
    setPopupType(type);
    setCourseInfoText("");
    setIsOpen(true);
  };

  const handleRequest = async () => {
    if (!title || !description) {
      toast.error("Please fill in all required fields.");
      return;
    }
    if (uploadError) {
      toast.error(uploadError);
      return;
    }

    const requestData = new FormData();
    requestData.append("title", title);
    requestData.append("description", description);
    requestData.append("type", selectedTab);
    if (uploadedFile) {
      requestData.append("file", uploadedFile);
    }

    try {
      const response = await createRequest(header, requestData);
      if (response.status === 201) {
        toast.success("Request created successfully.");
        setKey(!key);
        handleCancel();
      } else {
        toast.error(`Error: ${response.message}`);
      }
    } catch (error) {
      // console.error("Failed to create request:", error);
      toast.error("An error occurred while creating the request.");
    }
  };

  const handleCancel = () => {
    setTitle("");
    setDescription("");
    setUploadedFile(null);
    setUploadError("");
    setCourseInfoText("");
    setIsOpen(false);
  };
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
  const ALLOWED_FILE_TYPES = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/plain",
  ];
  const ALLOWED_EXTENSIONS = ["pdf", "docx", "xlsx", "txt", "odt"];
  const handleFileUpload = (files) => {
    const file = files[0];
    setUploadedFile(file);
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (!ALLOWED_EXTENSIONS.includes(fileExtension)) {
        setUploadError(
          "Unsupported file type. Please upload a PDF, Word, Excel, or plain text file."
        );
        return;
      }
      if (file.size > MAX_FILE_SIZE) {
        setUploadError(
          "File size exceeds the 5 MB limit. Please upload a smaller file."
        );
        return;
      }
      setUploadError("");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleFileUpload(e.dataTransfer.files);
  };

  const handleFileInputChange = (e) => {
    handleFileUpload(e.target.files);
  };

  const handleCancelUpload = () => {
    setUploadedFile(null);
    setUploadError("");
    fileInputRef.current.value = null;
  };

  const formatFileSize = (size) => {
    if (size < 1024) return `${size} B`;
    else if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    else if (size < 1024 * 1024 * 1024)
      return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    else return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (searchKey) {
          response = await searchRequests(header, selectedTab, searchKey, pageNo, recordsPerPage);
        } else {
          response = await getRequests(header, selectedTab, pageNo, recordsPerPage);
        }
  
        if (response.status !== "error") {
          setDataCount(response.data.count); 
          setData(response.data.results);    
        } else {
          toast.error("Error fetching data: " + response.message);
        }
      } catch (err) {
        toast.error("An unexpected error occurred.");
      }
    };
  
    fetchData();
  }, [selectedTab, key, searchKey, pageNo, recordsPerPage]);
  

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleRemoveRequests = async () => {
    if (checkedData.length === 0) {
      toast.error("No requests selected for deletion.");
      return;
    }
    try {
      const response = await removeRequest(header, checkedData);

      if (response.status === 200) {
        toast.success("Requests deleted successfully.");
        setCheckedData([]);
        setIsRemove(false);
        setKey(!key);
      } else {
        toast.error(`Error: ${response.message}`);
      }
    } catch (error) {
      // console.error("Failed to delete requests:", error);
      toast.error("An error occurred while deleting the requests.");
    }
  };
  const handleCancelClick = () => {
    setCheckedData([]);
    setUploadError("");
    setCourseInfoText("");
    setIsRemove(false);
  };

  const handleCourseSelect = (courseName) => {
    setCourseInfoText("Course already exists!!");
  };

  const handleClearSearch = () => {
    setCourseInfoText("");
    setCourseInfoText("");
  };

  const handleRequestSearch = (searchText) => {
    setSearchKey(searchText);
    return requestNames;
  };
  const handleClearRequestSearch = () => {
    setSearchKey("");
    setKey(!key);
  };
  const handleRequestValueClick = (requestName) => {
    setSearchKey(requestName);
  };

  return (
    <div className="admin-requests">
      <div className="admin-requests-title">Requests</div>

      <div className="admin-requests-tabs">
        <div
          className={`admin-requests-tabs-tab ${
            selectedTab === "course" ? "active" : ""
          }`}
          onClick={() => handleTabClick("course")}
        >
          Course
        </div>
        <div
          className={`admin-requests-tabs-tab ${
            selectedTab === "webinar" ? "active" : ""
          }`}
          onClick={() => handleTabClick("webinar")}
        >
          Webinar
        </div>
      </div>

      <div className="admin-requests-body">
        <div className="admin-requests-body-tab-content">
          <div className="admin-requests-body-tab-content-top">
            <button
              className="primary-button"
              onClick={() => openPopup(selectedTab)}
            >
              <FaPlus />
              Request
            </button>
            <div className="admin-requests-body-tab-content-top-search_box">
              <SearchBox
                searchData={[]}
                onSearch={handleRequestSearch}
                onValueClick={handleRequestValueClick}
                onClearSearch={handleClearRequestSearch}
                useDynamicSearch={true}
              />
            </div>
          </div>
          <div className="admin-requests-body-tab-content-table">
            <div className="admin-requests-body-tab-content-table-head">
              <div className="admin-requests-body-tab-content-table-head-item">
                <AdminCustomCheckbox
                  selectAll={true}
                  setCheckedData={setCheckedData}
                  checkedData={checkedData}
                  responseData={data}
                />
                Title
              </div>
              <div className="admin-requests-body-tab-content-table-head-item">
                Date
              </div>
              <div className="admin-requests-body-tab-content-table-head-item">
                Comment
              </div>
              <div className="admin-requests-body-tab-content-table-head-item">
                Status
              </div>
              <div className="admin-requests-body-tab-content-table-head-item">
                <FaRegTrashAlt
                  onClick={() => {
                    checkedData.length > 0
                      ? setIsRemove(true)
                      : handleRemoveRequests();
                  }}
                  className={itemClass}
                />
              </div>
            </div>
            <div className="admin-requests-body-tab-content-table-body">
              {data.length > 0 &&
                data.map((item) => (
                  <div
                    className="admin-requests-body-tab-content-table-body-row"
                    key={item.id}
                  >
                    <div className="admin-requests-body-tab-content-table-body-row-item">
                      <AdminCustomCheckbox
                        selectAll={false}
                        data_id={item.id}
                        setCheckedData={setCheckedData}
                        checkedData={checkedData}
                      />
                      {item.title}
                    </div>
                    <div className="admin-requests-body-tab-content-table-body-row-item">
                      {formatDate(item.created_on)}
                    </div>
                    <div className="admin-requests-body-tab-content-table-body-row-item">
                      {item.comment}
                    </div>
                    <div className="admin-requests-body-tab-content-table-body-row-item">
                      {item.status}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {data.length > 0 && (
            <AppPagination
              setCurrentPage={setPageNo}
              currentPage={pageNo}
              recordsPerPage={recordsPerPage}
              data_count={dataCount}
            />
          )}
        </div>
      </div>

      {/* Popup Modal */}
      {isOpen && (
        <PopupModal setIsOpen={setIsOpen}>
          <div className="admin-requests-modal">
            <div className="admin-requests-modal-title">
              Request a {popupType === "course" ? "Course" : "Webinar"}
            </div>
            <div className="admin-requests-modal-content">
              {popupType === "course" && (
                <div className="admin-requests-modal-content-search_box">
                  <SearchBox
                    searchData={courseNames}
                    onValueClick={handleCourseSelect}
                    onClearSearch={handleClearSearch}
                  />
                </div>
              )}
              {courseInfoText && (
                <div className="admin-requests-modal-content-message">
                  {courseInfoText}
                </div>
              )}
              <div className="admin-requests-modal-content-form">
                <label
                  className="admin-requests-modal-content-form-label"
                  htmlFor="title"
                >
                  {popupType === "course" ? "Course" : "Webinar"} Title
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="admin-requests-modal-content-form-input"
                  style={{ background: "white" }}
                />
              </div>
              <div className="admin-requests-modal-content-form">
                <label
                  className="admin-requests-modal-content-form-label"
                  htmlFor="description"
                >
                  Description
                </label>
                <span className="admin-requests-modal-content-form-help_text">
                  (Why do you prefer this{" "}
                  {`${popupType === "course" ? "course?" : "webinar?"}`})
                </span>
                <textarea
                  id="description"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="admin-requests-modal-content-form-input"
                  rows="4"
                />
              </div>

              <label
                className="admin-requests-modal-content-form-label"
                htmlFor="description"
              >
                Attach file (if any)
              </label>
              <div
                className="admin-requests-modal-content-body"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                style={{ background: "white" }}
              >
                {!uploadedFile ? (
                  <div className="admin-requests-modal-content-body-bottom">
                    <div className="admin-requests-modal-content-body-bottom-icon">
                      <FaCloudUploadAlt />
                    </div>
                    <div className="admin-requests-modal-content-body-bottom-drag_text">
                      Drag and drop files to upload
                    </div>
                    <div className="admin-requests-modal-content-body-bottom-subtext">
                      or
                    </div>
                    <div
                      className="admin-requests-modal-content-body-bottom-button"
                      onClick={() => fileInputRef.current.click()}
                    >
                      <div className="admin-requests-modal-content-body-bottom-button-text">
                        Browse
                      </div>
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                    />
                  </div>
                ) : (
                  <div className="admin-requests-modal-content-body-uploaded">
                    <div className="admin-requests-modal-content-body-uploaded-file">
                      <div className="admin-requests-modal-content-body-uploaded-file-icon">
                        <FaFileExcel />
                      </div>
                      <div className="admin-requests-modal-content-body-uploaded-file-info">
                        <div className="admin-requests-modal-content-body-uploaded-file-info-name">
                          {uploadedFile.name}
                        </div>
                        <div className="admin-requests-modal-content-body-uploaded-file-info-size">
                          {formatFileSize(uploadedFile.size)}
                        </div>
                      </div>
                    </div>

                    <div className="admin-requests-modal-content-body-uploaded-action">
                      {uploadError && (
                        <div className="admin-requests-modal-content-body-uploaded-action-error-message">
                          {uploadError}
                        </div>
                      )}
                      <div
                        className="admin-requests-modal-content-body-uploaded-action-cancel_button"
                        onClick={handleCancelUpload}
                      >
                        <div className="admin-requests-modal-content-body-uploaded-action-cancel_button-icon">
                          <FaTrashAlt />
                        </div>
                        <div className="admin-requests-modal-content-body-uploaded-action-cancel_button-text">
                          Remove
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="admin-requests-modal-bottom">
              <button className="primary-button" onClick={handleRequest}>
                Request {popupType === "course" ? "Course" : "Webinar"}
              </button>
              <button className="secondary-button" onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </div>
        </PopupModal>
      )}
      {isRemove && (
        <PopupModal setIsOpen={setIsRemove}>
          <div className="unenroll_confirm_modal-body">
            <div className="unenroll_confirm_modal-body-title">
              Remove Request
            </div>
            <div className="unenroll_confirm_modal-body-text">
              Are you sure you want to remove selected{" "}
              {checkedData.length < 2 ? "request" : "requests"}?
            </div>
            <div className="unenroll_confirm_modal-body-buttons">
              <button
                className="primary-button"
                onClick={() => {
                  handleRemoveRequests();
                }}
              >
                Confirm
              </button>
              <button className="secondary-button" onClick={handleCancelClick}>
                Cancel
              </button>
            </div>
          </div>
        </PopupModal>
      )}
    </div>
  );
};

export default AdminRequests;
