import React, { Component } from 'react';
import { FaTimes } from "react-icons/fa";
import './PopupModal.scss';

export class PopupModal extends Component {
  render() {
    const { setIsOpen, modalBgColor, containerBgColor, containerHeight } = this.props;

    const toggleModal = () => {
      setIsOpen(false);
    };

    return (
      <div
        className='popup-modal'
        style={{ backgroundColor: modalBgColor }}
      >
        <div
          className='popup-modal_container'
          style={{ backgroundColor: containerBgColor, minHeight:containerHeight }} 
        >
          <div onClick={toggleModal} className="popup-modal_container-close_button">
            <FaTimes />
          </div>
          <div className='popup-modal_container-content'>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default PopupModal;
