import React, { useState, useContext, useEffect } from "react";
import ChangePasswordPopup from "../components/ChangePasswordPopup";
import EditProfilePicturePopup from "../components/EditProfilePicturePopup";
import { getUserCourseData, updateUserDetails } from "../services/api.services";
import { AuthContext } from "../../../App";
import writing from "../../images/icons/writing.png";
import toast from "react-hot-toast";
import "./Settings.scss";

const Settings = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { state, dispatch } = useContext(AuthContext);
  const [userDetail, setUserDetails] = useState({});
  const [userCourses, setUserCourses] = useState(JSON.parse(localStorage.getItem('userCourses')));
  const [validationError, setValidationError] = useState("");
  const [editedUserDetails, setEditedUserDetails] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
  });
  const [componentKey, setComponentKey] = useState(0);

  useEffect(() => {
    getUserDetail();
  }, []);

  let header = {
    Authorization: "Token " + state.token,
  };

  const getUserDetail = async () => {
    await getUserCourseData(header)
      .then((resp) => {
        if (resp.data.isSuccess) {
          setUserDetails(resp.data.dataInfo);
          // setUserCourses(resp.data.dataInfo.course);
        } else {
          toast.error("Something went wrong!");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong!");
      });
  };
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleSubmit = async () => {
    try {
      const isEdited = Object.keys(editedUserDetails).some(
        (field) => editedUserDetails[field] !== ""
      );

      if (!isEdited) {
        toast.error("No changes made.");
        return;
      }

      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      const nameRegex = /^[A-Za-z]+$/;
      const phoneRegex = /^\d+$/;
      if (editedUserDetails.first_name) {
        if (!nameRegex.test(editedUserDetails.first_name)) {
          setValidationError(
            "First name should contain only alphabetic characters"
          );
          return;
        }
      }
      if (editedUserDetails.last_name) {
        if (!nameRegex.test(editedUserDetails.last_name)) {
          setValidationError(
            "Last name should contain only alphabetic characters"
          );
          return;
        }
      }
      if (editedUserDetails.mobile_number) {
        if (!phoneRegex.test(editedUserDetails.mobile_number)) {
          setValidationError("Mobile number should contain only numerics");
          return;
        } else if (
          editedUserDetails.mobile_number.length < 10 ||
          editedUserDetails.mobile_number.length > 15
        ) {
          setValidationError("Mobile number should be between 10 to 15 digits");
          return;
        }
      }
      if (editedUserDetails.email) {
        if (!emailRegex.test(editedUserDetails.email)) {
          setValidationError("Invalid email format");
          return;
        }
      }

      const updatedFields = {};

      for (const field in editedUserDetails) {
        if (editedUserDetails[field]) {
          updatedFields[field] = editedUserDetails[field];
        }
      }

      const res = await updateUserDetails(header, updatedFields);

      if (res.data.isSuccess) {
        toggleEditMode();
        setUserDetails((prevDetails) => ({
          ...prevDetails,
          ...updatedFields,
        }));
        setEditedUserDetails({
          first_name: "",
          last_name: "",
          mobile_number: "",
          email: "",
        });
        toast.success("User details updated successfully");
      } else {
        setValidationError("");
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("An error occurred while updating user details.");
    }
  };

  const handleInputChange = (field, value) => {
    setEditedUserDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const cancelEdit = () => {
    setEditedUserDetails({
      first_name: "",
      last_name: "",
      mobile_number: "",
      email: "",
    });
    setValidationError("");
    toggleEditMode();
  };

  const handleAvatarUpdate = (newAvatarUrl) => {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      avatar: newAvatarUrl,
    }));
    setComponentKey((prevKey) => prevKey + 1);
    let userDetails = JSON.parse(localStorage.getItem('userDetails'));
    userDetails.avatar = newAvatarUrl;
    localStorage.setItem('userDetails', JSON.stringify(userDetails));

    const event = new Event('userDetailsUpdated');
    window.dispatchEvent(event);
  };

  return (
    <div key={componentKey} className="settings">
      <div className="settings-profile">
        <div className="settings-profile-user">
          <EditProfilePicturePopup
            userAvatar={userDetail.avatar}
            updateFunction={updateUserDetails}
            header={header}
            onAvatarUpdate={handleAvatarUpdate}
          />
          <div className="settings-profile-user-title">
            <div className="settings-profile-user-title-name">
              {userDetail.first_name + " " + userDetail.last_name}
            </div>
            <div className="settings-profile-user-title-role">
              {userDetail.designation}
            </div>
          </div>
        </div>
        <div className="settings-profile-course-details">
          <div className="settings-profile-course-details-title">
            Courses Expiry
          </div>
          <div className="settings-profile-course-details-list">
            {userCourses.map((data) => (
              <div
                className="settings-profile-course-details-list-item"
                key={data["course"].id}
              >
                {data["course"].name} Ends on <br />
                <strong>20 Sep 2023</strong>
              </div>
            ))}
          </div>
          <div className="settings-profile-course-details-button">
            <button>Extend Subscription</button>
          </div>
        </div>
        <div className="settings-profile-change-password">
          <ChangePasswordPopup />
        </div>
      </div>
      <div className="settings-info">
        <div className="settings-info-personal">
          <div className="settings-info-personal-title">
            <div className="settings-info-personal-title-text">
              Personal Info
            </div>
            <div
              className="settings-info-personal-title-icon"
              onClick={toggleEditMode}
            >
              <img src={writing} alt="edit_icon"></img>
            </div>
          </div>
          <div className="settings-info-personal-body">
            {isEditMode ? (
              <>
                <div className="settings-info-personal-body-details">
                  <div className="settings-info-personal-body-details-key">
                    First Name
                  </div>
                  <div className="settings-info-personal-body-details-value">
                    <input
                      type="text"
                      value={editedUserDetails.first_name}
                      placeholder={userDetail.first_name}
                      onChange={(e) =>
                        handleInputChange("first_name", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="settings-info-personal-body-details">
                  <div className="settings-info-personal-body-details-key">
                    Last Name
                  </div>
                  <div className="settings-info-personal-body-details-value">
                    <input
                      type="text"
                      value={editedUserDetails.last_name}
                      placeholder={userDetail.last_name}
                      onChange={(e) =>
                        handleInputChange("last_name", e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="settings-info-personal-body-details">
                  <div className="settings-info-personal-body-details-key">
                    Contact
                  </div>
                  <div className="settings-info-personal-body-details-value">
                    <input
                      type="text"
                      value={editedUserDetails.mobile_number}
                      placeholder={userDetail.mobile_number}
                      onChange={(e) =>
                        handleInputChange("mobile_number", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="settings-info-personal-body-details">
                  <div className="settings-info-personal-body-details-key">
                    Email
                  </div>
                  <div className="settings-info-personal-body-details-value">
                    <input
                      type="email"
                      value={editedUserDetails.email}
                      placeholder={userDetail.email}
                      onChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                    />
                  </div>
                </div>

                {validationError && (
                  <div className="settings-info-personal-body-error">
                    {validationError}
                  </div>
                )}
                <div className="settings-info-personal-body-action">
                  <button
                    className="settings-info-personal-body-action-update"
                    onClick={handleSubmit}
                  >
                    Update
                  </button>
                  <button
                    className="settings-info-personal-body-action-cancel"
                    onClick={cancelEdit}
                  >
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <ReadOnlyField
                  label="First Name"
                  value={userDetail.first_name}
                />
                <ReadOnlyField label="Last Name" value={userDetail.last_name} />
                <ReadOnlyField
                  label="Contact"
                  value={userDetail.mobile_number}
                />
                <ReadOnlyField label="Email" value={userDetail.email} />
              </>
            )}
          </div>
        </div>
        <div className="settings-info-personal">
          <div className="settings-info-personal-title">
            <div className="settings-info-personal-title-text">
              My Courses Info
            </div>
          </div>
          <div className="settings-info-personal-body">
            <div className="settings-info-personal-body-details">
              <div className="settings-info-personal-body-details-key">
                Student ID
              </div>
              <div className="settings-info-personal-body-details-value">
                AS0001
              </div>
            </div>
            <div className="settings-info-personal-body-details">
              <div className="settings-info-personal-body-details-key">
                Courses
              </div>
              <div className="settings-info-personal-body-details-value">
                <div className="settings-info-personal-body-details-value-courses">
                  {userCourses.map((data) => (
                    <span key={data["course"].id}>{data["course"].name}</span>
                  ))}
                </div>
              </div>
            </div>
            <div className="settings-info-personal-body-details">
              <div className="settings-info-personal-body-details-key">
                Personal Tutor
              </div>
              <div className="settings-info-personal-body-details-value">
                Anas Alavudeen
              </div>
            </div>
            <div className="settings-info-personal-body-details">
              <div className="settings-info-personal-body-details-key">
                Expected End Date
              </div>
              <div className="settings-info-personal-body-details-value">
                20 Sep 2023
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const InputField = ({ label, value, onChange }) => (
//   <div className="settings-info-personal-body-details">
//     <div className="settings-info-personal-body-details-key">{label}</div>
//     <div className="settings-info-personal-body-details-value">
//       <input
//         type="text"
//         value={value === null ? "" : value}
//         onChange={(e) => onChange(e.target.value)}
//       />
//     </div>
//   </div>
// );

const ReadOnlyField = ({ label, value }) => (
  <div className="settings-info-personal-body-details">
    <div className="settings-info-personal-body-details-key">{label}</div>
    <div className="settings-info-personal-body-details-value">{value}</div>
  </div>
);

export default Settings;
