import React, { useContext, useEffect, useState, useRef } from 'react';
import './AppNavbar.scss';
import { useNavigate, Link, useLocation, parsePath } from 'react-router-dom';
import logo from '../../images/icons/logo.png';
import { FaTimes } from 'react-icons/fa';
import { AuthContext } from '../../../App';


const AppNavbar = ({setOpenMobileNavbar, navbarData, deviceWidth, openMobileNav}) => {
    const [toggleNavState, setToggleNavState] = useState(true);
    const { state, dispatch } = useContext(AuthContext);
    const [selectedNav, setSelectedNav] = useState(0);
    const [collapse, setCollapse] = useState(false);
    const ref = useRef();
    var navigate = useNavigate();
    var selectedCourseName = sessionStorage.getItem('userCourseName')
    const location = useLocation();

    useEffect(() => {
        // this was written to address the issue PP-246, to give the navbar selected items effect. Complete the code if needed or remove.
        var path = location.pathname.split('/')
    },[location.pathname])

    useEffect(() => {
        if(deviceWidth<=800){
            setCollapse(true);
        }
        else{
          setCollapse(false);
        }
      }, [deviceWidth])

    useEffect(() => {
        if(localStorage.selectedNavTab == null){
            setSelectedNav(0)
        }
        else{
            if(selectedNav !== localStorage.selectedNavTab) {
                setSelectedNav(parseInt(localStorage.selectedNavTab))
            }
        }
    }, [localStorage.selectedNavTab])

    useEffect(() => {
        handleNavbarToggle();
    },[collapse, openMobileNav])

    const handleNavbarClick = (index) =>{
        setSelectedNav(index)
        localStorage.setItem('selectedNavTab', index)
        handleCloseMobileNavBar();
    }
    const handleNavbarToggle = () => {
        if(deviceWidth > 450){
            if(collapse){
                if(toggleNavState) {
                    document.getElementById('collapsible_navbar').style.width = '210px';
                    setToggleNavState(false);
                }
                else{
                    document.getElementById('collapsible_navbar').style.width = '65px';
                    setToggleNavState(true);
                }
            }
            else{
                document.getElementById('collapsible_navbar').style.width = '215px';
                setToggleNavState(true);
            }
        }
        else{
            setToggleNavState(false);
            if(openMobileNav){
                document.getElementById('collapsible_navbar').style.left = '0';
                document.getElementById('collapsible_navbar').style.width = '200px';
            }
            else{
                document.getElementById('collapsible_navbar').style.left = '-100%';
            }
        }
    }

    const handleCloseMobileNavBar =() =>{   
        setOpenMobileNavbar(false)
    }

    const useOnClickOutside=(ref, handler)=> {
        useEffect(
          () => {
            const listener = (event) => {
              // Do nothing if clicking ref's element or descendent elements
              if (!ref.current || ref.current.contains(event.target)) {
                return;
              }
              handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
              document.removeEventListener("mousedown", listener);
              document.removeEventListener("touchstart", listener);
            };
          },
          [ref, handler]
        );
      }
      useOnClickOutside(ref, () => setOpenMobileNavbar(false));

    const handleLogout = () => {
        dispatch({
            type: 'LOGOUT',
            payload: {
              user: null,
              token: null
            }
        })
    }
    const handleCourseSelector = () => {
        navigate('/', { replace: true });
        window.location.reload();
    }
  return (
    <div className='app-navbar' id='collapsible_navbar' ref={ref}>
        <div className='app-navbar_logo'>
            <div className='app-navbar_logo-img' onClick={handleNavbarToggle}>
                <img src={logo} alt="logo"></img>
            </div>
            {deviceWidth < 450 ? 
                <button className='app-navbar_collapse-btn' onClick={handleCloseMobileNavBar}>
                    <FaTimes className={toggleNavState ? 'app-navbar_collapse-btn-close' :'app-navbar_collapse-btn-close animate'} />
                </button>
                :
                <>
                {collapse &&
                    <button className='app-navbar_collapse-btn' onClick={handleNavbarToggle}>
                        <FaTimes className={toggleNavState ? 'app-navbar_collapse-btn-close' :'app-navbar_collapse-btn-close animate'} />
                    </button>
                }
                </>
            }
        </div>
        <div className='app-navbar_body'>
            <ul className='app-navbar_body-lists'>
                {navbarData.map((value, key) => {
                    return  <Link
                    to={
                        value.name === 'Logout'
                            ? '/'
                            : value.name === 'Dashboard'
                                ? '/' + selectedCourseName
                                : value.name === 'Course Selector' || value.name === 'My Learning'
                                    ? '/'
                                    : value.name
                    }
                    key={key}
                    className='app-navbar_body-link'
                    onClick={value.name === 'Logout' ? handleLogout : value.name === 'Course Selector' ? handleCourseSelector : handleNavbarToggle}
                >
                    <li className={selectedNav === key ? 'app-navbar_body-lists-list selected' : 'app-navbar_body-lists-list'} onClick={() => handleNavbarClick(key)}>
                        <div className='app-navbar_body-link-tabs'>
                            <img src={value.icon} className='app-navbar_body-link-tabs-img' alt='icons'></img>
                            <div className={toggleNavState ? 'app-navbar_body-link-tabs-text responsive-hide':  'app-navbar_body-link-tabs-text'}>{value.name.split(' ', 1)[0]}<br></br> <span>{value.name.split(' ').slice(1).join(' ')}</span></div>
                        </div>
                    </li>
                </Link>
                })}
            </ul>
        </div>
    </div>
  )
}

export default AppNavbar
