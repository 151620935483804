import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../../App";
import toast from "react-hot-toast";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { createCheckoutSession } from "../services/api.services";
import "./AdminCheckout.scss";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
const AdminCheckout = () => {
  const [clientSecret, setClientSecret] = useState("");
  const { state } = useContext(AuthContext);
  const location = useLocation();

  let header = {
    Authorization: "Token " + state.token,
  };

  useEffect(() => {
    if (location.state && location.state.clientSecret) {
      setClientSecret(location.state.clientSecret);
    } else {
      toast.error("No client secret found in location state");
    }
  }, [location.state]);

  const options = { clientSecret };
  return (
    <div id="checkout">
      {clientSecret && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};
export default AdminCheckout;
