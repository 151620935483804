import React, {useEffect, useState} from 'react'
import './AppSidebar.scss'
import { Link, useNavigate } from 'react-router-dom';
import courseProgress from '../../images/icons/dna_course.png'
import avatar from '../../images/icons/user_avatar.png'
import { FaTimes } from 'react-icons/fa'

const AppSideBar = ({setSelectedNavbarData, setOpenMobileSidebarMenu, deviceWidth, openMobileSidebar}) => {
  const [toggleState, setToggleState] = useState(false);
  const [toggleSideBar, setToggleSideBar] = useState(true)
  const [sidecollapse, setSideCollapse] = useState(false);
  // var userCourses = JSON.parse(localStorage.userCourses)
  var currentCourseId = localStorage.currentCourseId
  const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('userDetails')));
  const [userCourses, setUserCourses] = useState(JSON.parse(localStorage.getItem('userCourses')));
  const navigate = useNavigate();


  useEffect(() => { 
    if(deviceWidth<=800){
      setSideCollapse(true);
    }
    else{
      setSideCollapse(false);
    }
  }, [deviceWidth])

  useEffect(() => {
    handleSideBarToggle()
  },[sidecollapse, openMobileSidebar])

  useEffect(() => {
    const handleStorageChange = () => {
      setUserDetails(JSON.parse(localStorage.getItem('userDetails')));
    };

    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('userDetailsUpdated', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('userDetailsUpdated', handleStorageChange);
    };
  }, []);

  const handleClickLink = async(courseId, courseName) =>{
        localStorage.setItem('currentCourseId', courseId)
        localStorage.setItem('selectedNavTab', 0)
        setSelectedNavbarData(0);
        navigate('../' + courseName);
        window.location.reload()

    }
    const handleSideBarToggle = () => {
      if(deviceWidth > 450){
        if(sidecollapse){
          if(toggleState){
            document.getElementById('collapsible_sidebar').style.width = '30%';
            setToggleState(false);
            setToggleSideBar(true);
          }
          else{
            document.getElementById('collapsible_sidebar').style.width = '10%';
            setToggleState(true);
            setToggleSideBar(false);
          }
        }
        else{
          document.getElementById('collapsible_sidebar').style.width = '20%';
          setToggleSideBar(true);
        }
      }
      else{
        setToggleSideBar(true);
        if(openMobileSidebar){
          document.getElementById('collapsible_sidebar').style.right = '0';
          document.getElementById('collapsible_sidebar').style.width = '200px';
        }
        else{
            document.getElementById('collapsible_sidebar').style.right = '-100%';
        }
      }
     
    }
    const handleCloseMobileSideBar = () => {
      setOpenMobileSidebarMenu(false)
    }
  return (
    <div className={toggleState ? 'app-sidebar' : 'app-sidebar shadow'} id='collapsible_sidebar'>        
          {sidecollapse &&
            <div className={toggleSideBar ? 'app-sidebar_collapsed expand' : "app-sidebar_collapsed"}>
              <div className="app-sidebar_collapsed-hamburger" onClick={() => {
                    if(deviceWidth < 450){
                      handleCloseMobileSideBar()
                    }
                    else{
                      handleSideBarToggle()
                    }
                  }
                }>
                  <span className="app-sidebar_collapsed-hamburger-bar"></span>
                  <span className="app-sidebar_collapsed-hamburger-bar"></span>
                  <span className="app-sidebar_collapsed-hamburger-bar"></span>
              </div>
            </div>
          }
            {userDetails &&
            <div className='app-sidebar_user'>
            <div className={toggleSideBar ? 'app-sidebar_user-avatar expand' : 'app-sidebar_user-avatar'}>
                <img src={userDetails.avatar ? userDetails.avatar : avatar} alt='userimage'></img>
            </div>
            {toggleSideBar &&
            <div className='app-sidebar_user-details'>
                <h3>{userDetails.first_name} {userDetails.last_name}</h3>
                <p>{userDetails.role}</p>
            </div>
            }
          </div>
            }
            {toggleSideBar &&
            <div className='app-sidebar_dynamic-selector'>
              <h3>Courses</h3>
              <ul>
              {userCourses && userCourses.map((data, index) => {
                  const courseData = data["course"];
                  return <li key={index}>
                      <Link to={'../' + courseData.name} className='app-sidebar_dynamic-selector-course'>
                          <div className={currentCourseId == courseData.id ? 'app-sidebar_dynamic-selector-course-link selected' : 'app-sidebar_dynamic-selector-course-link' } onClick={() => handleClickLink(courseData.id, courseData.name)}>
                              <div className='app-sidebar_dynamic-selector-course-link-icon'>
                                <img src={courseProgress} ></img>
                              </div>
                              <div className='app-sidebar_dynamic-selector-course-link-text'>
                              {courseData.name}
                              </div>
                              {/* <h3>{data.name}</h3> */}
                          </div>
                          </Link>
                      </li>
              })}
              </ul>
            </div>
            }
    </div>
  )
}

export default AppSideBar
