import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ResetPassword.scss";
import logo from "../../images/icons/logo.png";
import { useParams } from "react-router-dom";
import { resetPassword } from "../services/api.services";
import toast from "react-hot-toast";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (newPassword.trim() !== "") {
      if (newPassword.length < 8 || newPassword.length > 16) {
        setError("Password length should be between 8 to 16 characters");
      } else if (
        newPassword !== confirmNewPassword &&
        confirmNewPassword.trim() !== ""
      ) {
        setError("Passwords don't match.");
      } else {
        setError("");
      }
    }
  }, [newPassword, confirmNewPassword]);

  const handleSubmit = async () => {
    if (newPassword.trim() === "" || confirmNewPassword.trim() === "") {
      toast.error("Please fill in both password fields.");
      return;
    }
    if (error !== "") {
      toast.error("Please fix the errors before proceeding.");
      return;
    }
    const res = await resetPassword(token, newPassword);

    if (res.data.isSuccess) {
      toast.success("Password reset successfully");
      navigate("/");
    } else {
      console.error("Password change failed:", res.data.message);
      setError("Password change failed. Please try again.");
    }
  };

  return (
    <div className="passwrod-reset">
      <div className="password-reset-logo">
        <div className="password-reset-logo-img">
          <img src={logo} alt="logo"></img>
        </div>
      </div>
      <div className="password-reset-body">
        <div className="password-reset-body-title">Reset your password</div>
        <div className="password-reset-body-text">
          <div className="password-reset-body-text-top">
            Hi user, Welcome back.
          </div>
          <div className="password-reset-body-text-bottom">
            Please make sure your new password is hard to crack (and forget!).
          </div>
        </div>
        <input
          type="password"
          className="new_password"
          placeholder="Enter new password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />

        <input
          type="password"
          className="confirm_new_password"
          placeholder="Re-enter new password"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
        />
        {error !== "" && <div className="password-reset-error">{error}</div>}

        <button onClick={handleSubmit}>Change password</button>
      </div>
    </div>
  );
};

export default ResetPassword;
