import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../../App";
import toast from "react-hot-toast";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import {
  createStripeSubscription,
  getSetupSessionStatus,
  setPricingPlan,
} from "../services/api.services";
import "./StripeSetupSuccessPage.scss";

const StripeSetupSuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  let header = {
    Authorization: "Token " + state.token,
  };

  useEffect(() => {
    const getSessionStatus = async () => {
      const searchParams = new URLSearchParams(location.search);
      const sessionId = searchParams.get("session_id");

      if (sessionId) {
        try {
          const response = await getSetupSessionStatus(header, sessionId);
          if (response.status === 200) {
            const planId = response.data.sessionInfo.plan_id;
            invokeSetPricingPlan(planId);
          } else {
            console.error("Error fetching session status");
            setLoading(false);
          }
        } catch (error) {
          console.error("Error fetching session status");
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    getSessionStatus();
  }, [location.search]);

  const invokeSetPricingPlan = async (planId) => {
    try {
      const response = await setPricingPlan(header, planId);
      if (response.status == 200) {
        invokeCreateStripeSubscription();
      } else {
        toast.error("Something went wrong, Please Login and try again!!");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error setting Pricing Plan:", error);
      setLoading(false);
    }
  };

  const invokeCreateStripeSubscription = async () => {
    try {
      const response = await createStripeSubscription(header);
      if (response.data.isSuccess) {
        toast.success(response.data.message, {
          duration: 5000,
        });
        setIsSuccess(true);
        dispatch({
          type: "UPDATE_PRICING_PLAN",
          payload: {
            is_organisation_pricing_plan: true,
          },
        });
      } else {
        toast.error(response.message.response.data.message);
        setIsSuccess(false);
      }
    } catch (error) {
      console.error("Error fetching credit details:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="stripe_setup_success_page">
      <div className="stripe_setup_success_page-body">
        {loading ? (
          <div>Loading...</div> // Show loading indicator if still loading
        ) : (
          <>
            <div className="stripe_setup_success_page-body-top">
              {isSuccess ? (
                <>
                  <div className="stripe_setup_success_page-body-top-icon">
                    <FaCheckCircle
                      style={{
                        width: "85px",
                        height: "85px",
                        color: "#1ccda6",
                      }}
                    />
                  </div>
                  <div className="stripe_setup_success_page-body-top-text">
                    Subscription Created Successfully!!
                  </div>
                </>
              ) : (
                <>
                  <div className="stripe_setup_success_page-body-top-icon">
                    <FaTimesCircle
                      style={{
                        width: "85px",
                        height: "85px",
                        color: "#ff6347",
                      }}
                    />
                  </div>
                  <div className="stripe_setup_success_page-body-top-text">
                    Subscription Creation Failed. Please Try Again.
                  </div>
                </>
              )}
            </div>
            <div className="stripe_setup_success_page-body-bottom">
              <button
                style={{
                  backgroundColor: isSuccess ? "#1ccda6" : "#ff6347",
                }}
                onClick={() => navigate(isSuccess ? "/learning/admin/" : "/pricing_plan_selector")}
              >
                {isSuccess ? "Continue" : "Try Again"}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default StripeSetupSuccessPage;
